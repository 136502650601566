import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Clients', '/clients', null, 'accessibility', null, false, 0), 
    // new Menu (3, 'produit', '/produitCommande', null, 'view_module', null, false, 0), 
    // new Menu (4, 'Creer un dossier', '/dossier', null, 'book', null, false, 0),
   // new Menu (3,"Dossier attente", '/dossier_attente', null, 'view_module', null, false, 0), 

    new Menu (4,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (5, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Clients', '/clients', null, 'accessibility', null, false, 0), 
  //  new Menu (3,"Dossier attente", '/dossier_attente', null, 'view_module', null, false, 0), 

    new Menu (4,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (5, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 
]



























