import { SnackBarService } from './../../services/snack-bar.service';
import { ClientService } from './../../services/client.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-client',
  templateUrl: './delete-client.component.html',
  styleUrls: ['./delete-client.component.scss']
})
export class DeleteClientComponent {

  constructor(public dialogRef: MatDialogRef<DeleteClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private clientService: ClientService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.clientService.deleteClient(this.data.id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Supression Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }
}
