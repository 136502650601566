import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ClientService } from 'src/app/services/client.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ajout-client',
  templateUrl: './ajout-client.component.html',
  styleUrls: ['./ajout-client.component.scss']
})
export class AjoutClientComponent {

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(
    private _snckBar: SnackBarService,
    public dialogRef: MatDialogRef<AjoutClientComponent>,
    public userService:UserService,
    public appSettings:AppSettings,
    private fb: FormBuilder,
    private clientService: ClientService)
     {
    this.settings = this.appSettings.settings;     
  }

  public typePiece= ['passport', 'permis de conduire', "carte d'identité"]
  ngOnInit(): void {
   
  }
  clientForm =  this.fb.group({
    name: ['',  {
      validators: [
      Validators.required,
    ]},],
    prenoms: ['',  {
      validators: [
      Validators.required,
    ]},],
    telephone: ['',  {
      validators: [
      Validators.required,
    ]},],
    type_identite: ['',  {
      validators: [
      Validators.required,
    ]},],
    user_id: ['', []],
    numero_piece: ['',  {
      validators: [
      Validators.required,
    ]},],
    numero_compte: ['',  {
      validators: [
      Validators.required,
    ]},]
   
   
  });
  onSubmit(){
    //  this.etatPadding = false;
          this.user_id.setValue(this.userService.getUserDetails()._id)
         this.clientService.addClient(this.clientForm.value).subscribe(res => {
           this.dialogRef.close();
           console.log("user",this.clientForm.value)
           this._snckBar.openSnackBar("client Ajouté!!", "Fermer");
          //  this.etatPadding = true;

         });
   
    
  }
  get user_id(){
    return this.clientForm.get('user_id');
  }
  close(): void {
    this.dialogRef.close();
  }
}
