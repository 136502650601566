import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  ap = `${baseUrl}zones`;
  constructor(private http: HttpClient) { }

  public addClient(client: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addClient`, client).pipe(
      retry(3)
    );
  }

  public updateClient(id: any, client): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateClient/${id}`, client).pipe(
      retry(3)
    );
  }

  public getPaginatedClient(id): Observable<any>{
    return this.http.get<any>(`${this.ap}/getPaginatedClient/${id}`).pipe(
      retry(3)
    );
  }
  
  public getAllClient(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllClient`).pipe(
      retry(3)
    );
  }

  public deleteClient(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deleteClient/${id}`).pipe(
      retry(3)
    );
  }

  public getClientById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getClientById/${id}`).pipe(
      retry(3)
    );
  }
}
