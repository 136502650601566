import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {
  api = `${baseUrl}commandes`;
 
  constructor(private http: HttpClient) { }

  
  public validerPackage(id: any,commande_id:any): Observable<any>{
    return this.http.get<any>(`${this.api}/validerPackage/${id}/${commande_id}`).pipe( 
      retry(3)
    )
  }
  public rejeterPackage(id: any,commande_id:any): Observable<any>{
    return this.http.get<any>(`${this.api}/rejeterPackage/${id}/${commande_id}`).pipe( 
      retry(3)
    )
  }
  public commandesTraiteesByDistributeur(id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/commandesTraiteesByDistributeur/${id}`).pipe( 
      retry(3)
    )
  }

  public addCommande(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addCommande`, commande).pipe(
      retry(3)
    );
  }

  public addCommandes(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addCommandes`, commande).pipe(
      retry(3)
    );
  }

  public commandeCount(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/commandeCount`, commande).pipe(
      retry(3)
    );
  }
  public chercherUneCommandeParSaReference(referenceObj: any): Observable<any>{
    return this.http.post<any>(`${this.api}/chercherUneCommandeParSaReference`, referenceObj).pipe(
      retry(3)
    );
  }
  
  public addStock(stock: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addStock`, stock).pipe(
      retry(3)
    );
  }
  public updateStock(id: any, zone): Observable<any>{
    return this.http.put<any>(`${this.api}/updateStock/${id}`, zone).pipe(
      retry(3)
    );
  }
  public addAprovisonement(stock: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addAprovisonement`, stock).pipe(
      retry(3)
    );
  }
  public AllCommandeAttenteDistributeur(id:any): Observable<any>{
    return this.http.get<any>(`${this.api}/AllCommandeAttenteDistributeur/${id}`).pipe( 
      retry(3)
    )
  }
  public  AllCommandeAttenteDistributeurTraite(id:any): Observable<any>{
    return this.http.get<any>(`${this.api}/AllCommandeAttenteDistributeurTraite/${id}`).pipe( 
      retry(3)
    )
  }
  public  AllCommandeAttenteDistributeurTraiteById(id:any): Observable<any>{
    return this.http.get<any>(`${this.api}/AllCommandeAttenteDistributeurTraiteById/${id}`).pipe( 
      retry(3)
    )
  }
  public ListeCommission(): Observable<any>{
    return this.http.get<any>(`${this.api}/ListeCommission`).pipe( 
      retry(3)
    )
  }
  public allStock(): Observable<any>{
    return this.http.get<any>(`${this.api}/allStock`).pipe( 
      retry(3)
    )
  }
  public StockById(id): Observable<any>{
    return this.http.get<any>(`${this.api}/StockById/${id}`).pipe( 
      retry(3)
    )
  }

  public paginatedCommandeAttenteForDistributeurBanque( page: any,id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/paginatedCommandeAttenteForDistributeurBanque/${page}/${id}`).pipe( 
      retry(3)
    )
  }
  
  public paginatedCommandeAttenteForDistributeur( page: any,id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/paginatedCommandeAttenteForDistributeur/${page}/${id}`).pipe( 
      retry(3)
    )
  }
 

  public paginatedCommandeTraiteForDistributeur( page: any,id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/paginatedCommandeTraiteForDistributeur/${page}/${id}`).pipe( 
      retry(3)
    )
  }
  public paginatedCommandeAllTraiteForDistributeur( page: any,id: any): Observable<any>{
    return this.http.get<any>(`${this.api}/  paginatedCommandeAllTraiteForDistributeur/${page}/${id}`).pipe( 
      retry(3)
    )
  }



  public commission(): Observable<any>{
    return this.http.get<any>(`${this.api}/commission`).pipe( 
      retry(3)
    )
  }
  public CommissionListe(): Observable<any>{
    return this.http.get<any>(`${this.api}/CommissionListe`).pipe( 
      retry(3)
    )
  }
  
  public relationCommandes(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/relationCommandes`, commande).pipe( 
      retry(3)
    )
  }
  // public relationCommandes(id, id_commande,id_stock): Observable<any>{
  //   return this.http.get<any>(`${this.api}/relationCommandes/${id}/${id_commande}/${id_stock}`).pipe( 
  //     retry(3)
  //   )
  // }
  public revoqueCommande(id,id_user): Observable<any>{
    return this.http.get<any>(`${this.api}/revoqueCommande/${id}/${id_user}`).pipe( 
      retry(3)
    )
  }
  public relationCommande(id, id_commande): Observable<any>{
    return this.http.get<any>(`${this.api}/relationCommande/${id}/${id_commande}`).pipe( 
      retry(3)
    )
  }
  public listeCommande(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listeComman/${id}`).pipe(
      retry(3)
    );
  }
  public getCommandeById(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getCommandeById/${id}`).pipe(
      retry(3)
    );
  }
  public getCommandeByIds(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getCommandeByIds/${id}`).pipe(
      retry(3)
    );
  }
  public CommandeByFournisseur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/CommandeByFournisseur`).pipe(
      retry(3)
    );
  }
  public  listeCommandeAttente(id): Observable<any>{
    return this.http.get<any>(`${this.api}/listesCommandeAttente/${id}`).pipe(
      retry(3)
    );
  }
 
  public listeCommandeAttenteP(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listeCommandeAttenteP`).pipe(
      retry(3)
    );
  }
   public commandeClientById(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/commandeClientById`).pipe(
      retry(3)
    );
  }
   public listeCommandeTraite(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listesCommandeTraite/${id}`).pipe(
      retry(3)
    );
  }
  public listeCommandeFourniTraite(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listeCommandeFourniTraite`).pipe(
      retry(3)
    );
  }
  public listeCommandeFourni(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listeCommandeFourni`).pipe(
      retry(3)
    );
  }
  public listeCommandeFournisseur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/listeCommandeFournisseur`).pipe(
      retry(3)
    );
  }
  public AllCommandeAttente(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/AllCommandeAttente`).pipe(
      retry(3)
    );
  }
  public AllCommandeRejeter(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/AllCommandeRejeter`).pipe(
      retry(3)
    );
  }
  public AllCommandeTraite(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/AllCommandeTraite`).pipe(
      retry(3)
    );
  }
  public AllCommandeFournisseur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/AllCommandeFournisseur`).pipe(
      retry(3)
    );
  }
  public deleteCommande(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/deleteCommande`, commande).pipe(
      retry(3)
    );
  }
  public deleteStock(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteStock/${id}`).pipe(
      retry(3)
    );
  }
  public ViewRelation(id): Observable<any>{
    return this.http.get<any>(`${this.api}/ViewRelation/${id}`).pipe(
      retry(3)
    );
  }
  public cancelCommande(id): Observable<any>{
    return this.http.get<any>(`${this.api}/cancelCommande/${id}`).pipe(
      retry(3)
    );
  }
  public paypal(id): Observable<any>{
    return this.http.get<any>(`${this.api}/paypal/${id}`).pipe(
      retry(3)
    );
  }
  public livraisonCommande(id): Observable<any>{
    return this.http.get<any>(`${this.api}/livrerCommande/${id}`).pipe(
      retry(3)
    );
  }
  // public livraisonCommandeFournisseurs(commande: any): Observable<any>{
  //   return this.http.post<any>(`${this.api}/livraisonCommandeFournisseur`,commande).pipe(
  //     retry(3)
  //   );
  // }
  public livraisonCommandeFournisseur(id,id_user): Observable<any>{
    return this.http.get<any>(`${this.api}/livraisonCommandeFournisseur/${id}/${id_user}`).pipe(
      retry(3)
    );
  }
  public redicat(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/redicat`, commande).pipe( 
      retry(3)
    )
  }
  public avance(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/avance`, commande).pipe( 
      retry(3)
    )
  }
  public avanceClient(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/avanceClients`, commande).pipe( 
      retry(3)
    )
  }
  public livraisonCommandeFournisseurs(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/livraisonCommandeFournisseur`, commande).pipe( 
      retry(3)
    )
  }
   
  public livraisonCommandes(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/livraisonCommandes`, commande).pipe( 
      retry(3)
    )
  }
}



