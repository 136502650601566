import { ActivatedRoute } from '@angular/router';
import { timer, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { SharedService } from 'src/app/services/shared.service';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { Produit } from '../all-produit/Produit.model';
import { CommandePanierComponent } from '../commande-panier/commande-panier.component';
import { ModalCommandeComponent } from '../modal-commande/modal-commande.component';
import { Passport } from 'src/staticData/passport';
import { Package } from 'src/staticData/package';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {

  public produits: Produit[]
  prixToutesLesCommandes: number=0
  users: any[];
  public passport=[] 
  public package=[] 
  tutorials:Produit[] = [];
  //currentTutorial: User = {};
  currentIndex = -1;
  title = '';
  
  page = 1;
  count = 0;
  pageSize = 3;
  pageSizes = [3, 6, 9];
  
   public searchText: string;
   public passportLibelle= ''
  subscription: Subscription;
  public taille: number
  public id_client: any
  public id_passport: any
  public libelle_passport: any
  tableauObjetCommande= [
    {id: "",client: "", avance: "", livraison: "", nomProduit: "" , nomZone: "", quantite: "", zone_id: "",  montant: ""}
  ]
  
  constructor(
     public print: PrintService,
     private sharedService: SharedService,
     public activateRoute: ActivatedRoute,
     private dialog: MatDialog,
     public activatedRoute:  ActivatedRoute,
     private produitService:ProduitService,
     public appSettings:AppSettings,
     public userService:UserService,
    public role: RoleService
     ){

    if(localStorage.getItem('tableauToAdd') == null){
      localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
      this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))

   }
    else{
     this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
    }
  }
  
  ngOnInit(): void { 
    this.id_client = this.activateRoute.snapshot.paramMap.get('client_id')
    this.id_passport = this.activateRoute.snapshot.paramMap.get('passport_id')
     this.libelle_passport = this.activateRoute.snapshot.paramMap.get('libelle_passport')
    this.trouverLePassportDuPackage(this.id_passport)
    this.subscription =  timer(0, 3000).subscribe(res => {
    });
    this.retrieveTutorials();  
    this.getPassport();
    this.getPackageByPassportId(this.id_passport)
  }
  getTaille(){
    return this.sharedService.taille
  }

  calculePrixTotalDesCommandes(arrayCommandes){
    for (let i = 0; i < arrayCommandes.length; i++) {
             this.prixToutesLesCommandes += Number(arrayCommandes[i].montant) 
    }
  }
  viewCurrentCommandeInPanier(client_id){
    this.dialog.open(CommandePanierComponent, {
      width: '800px',
      height: '200px',
      data:{ client_id:client_id}
    })
  }

  verifSiProduitEstDejaCommande(idProduit){
    this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))
    let filtredData= this.tableauObjetCommande.filter(data=> data.id= idProduit)
    if(filtredData.length > 0){
      return true
    }
    else{
      return false
    }
  }
  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};
  
    if (searchTitle) {
      params[`title`] = searchTitle;
    }
  
    if (page) {
      params[`page`] = page - 1;
    }
  
    if (pageSize) {
      params[`size`] = pageSize;
    }
  
    return params;
  }
  
  public getPackageByPassportId(idPassport: number){
    for (let i = 0; i < Package.length; i++) {
      if(Package[i].passportId== idPassport){
        this.package[i]= Package[i]
      }
    }
    let c= this.package.filter((item: any)=> item != undefined)
    this.package= c
  }

  getPassport(){
    this.passport  = Passport
  }
  retrieveTutorials(): void {
    this.tutorials = null
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    
         
  
    this.produitService.getAllProduitByDistributeur(params.page).subscribe((res: any)=>{
        this.tutorials = res.docs;
        this.count = res.totalDocs;
        this.page = params.page;
      })
  }
  
  handlePageChange(event: number): void {
    this.page = event;
    this.produitService.getAllProduitByDistributeur(this.page).subscribe((res: any)=>{
      this.tutorials = res.docs;
      this.count = res.totalDocs
    })
  }
  
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }
  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }
 /* getProduits(){
    this.produitService.getProduits().subscribe(res => {
      this.produits = res;
    });
}*/
  makeCommande(package_id:any,package_libelle: string,client_id:any,passport_id:any,libelle_passport:any){
    this.dialog.open(ModalCommandeComponent, {
      width: '520px',
      height: '500px',
      data: {id: package_id, package_libelle: package_libelle, client_id:client_id,passport_id:passport_id,libelle_passport:libelle_passport }
    })
}

trouverLePassportDuPackage(idPackage: any){
  /* Le passport auquel ce package est rattaché */
  let passport = Passport.filter((item: any)=> item.id == idPackage)
  this.passportLibelle= passport[0].libelle
}

}
