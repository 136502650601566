import { ActivatedRoute } from '@angular/router';
import { timer, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { SharedService } from 'src/app/services/shared.service';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { Produit } from '../all-produit/Produit.model';
import { CommandePanierComponent } from '../commande-panier/commande-panier.component';
import { ModalCommandeComponent } from '../modal-commande/modal-commande.component';
import { Passport } from 'src/staticData/passport';


@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss']
})
export class PassportComponent implements OnInit {
  public produits: Produit[]
  prixToutesLesCommandes: number=0
  users: any[];
  public passport=[] 
  tutorials:Produit[] = [];
  //currentTutorial: User = {};
  currentIndex = -1;
  title = '';
  
  page = 1;
  count = 0;
  pageSize = 3;
  pageSizes = [3, 6, 9];
  
   public searchText: string;
   
  subscription: Subscription;
  public taille: number
  public id_client: any
  tableauObjetCommande= [
    {id: "",client: "", avance: "", livraison: "", nomProduit: "" , nomZone: "", quantite: "", zone_id: "",  montant: ""}
  ]
  
  constructor(
     public print: PrintService,
     private sharedService: SharedService,
     public activateRoute: ActivatedRoute,
     private dialog: MatDialog,
     public activatedRoute:  ActivatedRoute,
     private produitService:ProduitService,
     public appSettings:AppSettings,
     public userService:UserService,
    public role: RoleService,
    private _router: Router
     ){
    this.id_client = this.activateRoute.snapshot.paramMap.get('id')

    if(localStorage.getItem('tableauToAdd') == null){
      localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
      this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))

   }
    else{
     this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
    }
  }
  
  ngOnInit(): void { 
    this.subscription =  timer(0, 3000).subscribe(res => {
    });
    this.retrieveTutorials();  
    this.getPassport();
    this.id_client = this.activateRoute.snapshot.paramMap.get('client_id')
    console.log("id client",this.id_client);
  }
  getTaille(){
    return this.sharedService.taille
  }

  calculePrixTotalDesCommandes(arrayCommandes){
    for (let i = 0; i < arrayCommandes.length; i++) {
             this.prixToutesLesCommandes += Number(arrayCommandes[i].montant) 
    }
  }
  viewCurrentCommandeInPanier(){
    this.dialog.open(CommandePanierComponent, {
      width: '800px',
      height: '200px',
    })
  }

  verifSiProduitEstDejaCommande(idProduit){
    this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))
    let filtredData= this.tableauObjetCommande.filter(data=> data.id= idProduit)
    if(filtredData.length > 0){
      return true
    }
    else{
      return false
    }
  }
  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};
  
    if (searchTitle) {
      params[`title`] = searchTitle;
    }
  
    if (page) {
      params[`page`] = page - 1;
    }
  
    if (pageSize) {
      params[`size`] = pageSize;
    }
  
    return params;
  }
  


  getPassport(){
    this.passport  = Passport
  }
  retrieveTutorials(): void {
    this.tutorials = null
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    
         
  
    this.produitService.getAllProduitByDistributeur(params.page).subscribe((res: any)=>{
        this.tutorials = res.docs;
        this.count = res.totalDocs;
        this.page = params.page;
        console.log("User bella",   this.page);
      })
  }
  
  handlePageChange(event: number): void {
    this.page = event;
    this.produitService.getAllProduitByDistributeur(this.page).subscribe((res: any)=>{
      this.tutorials = res.docs;
      this.count = res.totalDocs
    })
  }
  
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }
  redirectToPackagePage(id_client: any,id_passport:any,libelle_passport:any){
    this._router.navigateByUrl('/package/'+ id_client +'/'+ id_passport+'/'+libelle_passport) 
   }
  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }
 /* getProduits(){
    this.produitService.getProduits().subscribe(res => {
      this.produits = res;
    });
}*/
  makeCommande(id_client: any,id){
    this.dialog.open(ModalCommandeComponent, {
      width: '520px',
      height: '600px',
      data: {idClient: this.id_client,id_package: id }
    })
}

}
