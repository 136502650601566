import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommandeService } from 'src/app/services/commande.service';
import { UserService } from 'src/app/services/user.service';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-commande-panier',
  templateUrl: './commande-panier.component.html',
  styleUrls: ['./commande-panier.component.scss']
})
export class CommandePanierComponent implements OnInit{
  observer = new Observable()
  tabCommandes: []
   id_client :any
  tableauObjetCommande= [
    {id: "",passport_id: "",passport_libelle: "", package_id: "",package_libelle: "", quartier: "", zone_id: "" ,produit_id: "", produit_libelle: "",quantite: "",  montant: ""}
  ]
  public id: any
  prixToutesLesCommandes: number=0
  subscription: Subscription;
  
  displayedColumns: string[] = ['passport_id','passport_libelle', 'package_id','package_libelle', 'quantite','zone_id','quartier','produit_id','produit_libelle','montantTotal'];
    constructor(
      private activatedRoute: ActivatedRoute,
      public activateRoute: ActivatedRoute,

      public dialogRef: MatDialogRef<CommandePanierComponent>,
      private _snckBar: SnackBarService,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private sharedService:SharedService,
     private commandeService: CommandeService,
     public userService: UserService,
    private dialog: MatDialog,
    ){
      
     this.tabCommandes = JSON.parse((localStorage.getItem('tableauToAdd')))
    
    }
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
    });
    this.id_client = this.activateRoute.snapshot.paramMap.get('passport_id')

    this.calculePrixTotalDesCommandes(this.tabCommandes)
  console.log("route ",this.data.client_id)
  }

  calculePrixTotalDesCommandes(arrayCommandes){
      for (let i = 1; i < arrayCommandes.length; i++) {
        this.prixToutesLesCommandes += Number(arrayCommandes[i].total) 
      }
    
  }

  retirerUnProduitDuPanier(produit, montantTotal){
    let tempoArray: any
    tempoArray = this.tabCommandes.filter((data: any) => data.produit_libelle == produit  )
      console.log(tempoArray)
      this.tabCommandes = tempoArray
      localStorage.setItem("tableauToAdd", JSON.stringify(this.tabCommandes))

      this.sharedService.taille= this.tabCommandes.length
      this.prixToutesLesCommandes= Number(this.prixToutesLesCommandes) - Number(montantTotal) 
    }
  
  getAllOrders() {
    this.observer.subscribe(() => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (this.tabCommandes); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return this.tabCommandes
    })}


  
    ajoutCommandeFinale(){
     this.tabCommandes = JSON.parse((localStorage.getItem('tableauToAdd')))
        const obj={
          panier: this.tabCommandes.filter((data: any)=> data.id != ""),
          user_id: this.userService.getUserDetails()._id,
          montantTotal: this.prixToutesLesCommandes,
          client_id: this.data.client_id,
          societe: this.userService.getUserDetails().societe,
          agence_name: this.userService.getUserDetails().name,
          agence_telephone: this.userService.getUserDetails().tel

        }

         this.commandeService.addCommandes(obj)
         .subscribe(()=>{
           console.log("objet commande",obj)
           this._snckBar.openSnackBar("Commande Ajoutée avec succès!!", "Fermer");
           localStorage.removeItem("tableauToAdd")
           this.sharedService.taille=1
           this.dialogRef.close();
           localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
           console.log("Bonjour",obj)

         },
         (error)=>{
           console.log("Bonjour",error)

         })



      }
   
}

export interface PeriodicElement {
  idProduit: string;
  nomClient: string;
  quantite: string;
  idZone: string;
  montantTotal: string;
}