import { ConfirmationUpdateComponent } from './confirmation-update/confirmation-update.component';
import { ProduitCommandeComponent } from './pages/produit-commande/produit-commande.component';
import { CommandeAttenteComponent } from './pages/commande-attente/commande-attente.component';
import { AllRegionComponent } from './pages/all-region/all-region.component';
import { AllCommuneComponent } from './pages/all-commune/all-commune.component';
import { AllPaysComponent } from './pages/all-pays/all-pays.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AuthGuard } from './guards/auth.guard';
import { AllProduitComponent } from './pages/all-produit/all-produit.component';
import { AllCategorieComponent } from './pages/all-categorie/all-categorie.component';
import { ZoneComponent } from './pages/zone/zone.component';
import { AllStockComponent } from './pages/all-stock/all-stock.component';
import { AllChauffeurComponent } from './pages/all-chauffeur/all-chauffeur.component';
import { CommandeTraiteeComponent } from './pages/commande-traitee/commande-traitee.component';
import { DatatableeComponent } from './pages/datatablee/datatablee.component';
import { LoginComponent } from './login/login.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { ListeClientComponent } from './client/liste-client/liste-client.component';
import { ClientCommandeComponent } from './client-commande/client-commande.component';
import { PassportComponent } from './pages/passport/passport.component';
import { PackageComponent } from './pages/package/package.component';
import { DossierAttenteComponent } from './pages/dossier-attente/dossier-attente.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserInfoComponent } from './pages/profile/user-info/user-info.component';
export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
           
            { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'ui', loadChildren: () => import('./pages/ui/ui.module').then(m => m.UiModule), data: { breadcrumb: 'UI' } },
            { path: 'form-controls', loadChildren: () => import('./pages/form-controls/form-controls.module').then(m => m.FormControlsModule), data: { breadcrumb: 'Form Controls' } },
            { path: 'tables', loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
            { path: 'icons', loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule), data: { breadcrumb: 'Material Icons' } },
            { path: 'drag-drop', loadChildren: () => import('./pages/drag-drop/drag-drop.module').then(m => m.DragDropModule), data: { breadcrumb: 'Drag & Drop' } },
            { path: 'schedule', loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule), data: { breadcrumb: 'Schedule' } },
            { path: 'mailbox', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
            { path: 'chat', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Chat' } },
            { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
            { path: 'charts', loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
            { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },          
            { path: 'profile', loadChildren: () => import ('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } }, 
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
            { path: 'produit', component:AllProduitComponent,data: { breadcrumb: 'Produit' } },
            { path: 'categorie', component:AllCategorieComponent,data: { breadcrumb: 'Categorie' } },
            { path: 'zone', component:ZoneComponent,data: { breadcrumb: 'Zone' } },
            { path: 'stock', component:AllStockComponent,data: { breadcrumb: 'stock' } },
            { path: 'chauffeur', component:AllChauffeurComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'Localisation', component:AllChauffeurComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'allPays', component:AllPaysComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'allRegion', component: AllRegionComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'allCommune', component: AllCommuneComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'produitCommande', component: ProduitCommandeComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'commandeAttente', component: CommandeAttenteComponent,data: { breadcrumb: 'Dossiers encours' } },
            { path: 'commandeTraite', component: CommandeTraiteeComponent,data: { breadcrumb: 'Chauffeur' } },
            { path: 'dataTables', component: DatatableeComponent,data: { breadcrumb: 'DataTable' } },
            { path: 'update_password', component: UpdatePasswordComponent,data: { breadcrumb: 'Home' } },
            {path: 'dossier_attente', component: DossierAttenteComponent ,data: { breadcrumb: 'client' } },

            {path: 'clients', component: ListeClientComponent ,data: { breadcrumb: 'client' } },
            {path: 'clientCommande/:id', component: ClientCommandeComponent ,data: { breadcrumb: 'Commande' } },
            {path: 'package/:client_id/:passport_id/:libelle_passport', component: PackageComponent ,data: { breadcrumb: 'package' } },

            {path: 'passport/:client_id', component: PassportComponent ,data: { breadcrumb: 'passport' } },

        ],canActivate: [AuthGuard]
    },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'login', component: LoginComponent },

    { path: 'code/:id', component: ConfirmationUpdateComponent},
    { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
]; 

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, 
            // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }