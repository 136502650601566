import { ActivatedRoute } from '@angular/router';
import { ProduitCommandeComponent } from './../produit-commande/produit-commande.component';
import { CommandePanierComponent } from './../commande-panier/commande-panier.component';
import { ProduitService } from '../../services/produit.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Settings } from './../../app.settings.model';
import { PrintService } from './../../services/print.service';
//import { Produit } from './../all-produit/Produit.model';
import { Produit } from 'src/staticData/produit';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit,Inject } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { ZoneService } from './../../services/zone.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';
import { Observable, Subscription,BehaviorSubject,  timer } from 'rxjs';
import { RegionService } from 'src/app/services/region.service';
import { CommuneService } from 'src/app/services/commune.service';
import { SharedService } from 'src/app/services/shared.service';
import { ClientService } from 'src/app/services/client.service';
import { Passport } from 'src/staticData/passport';



@Component({
  selector: 'app-modal-commande',
  templateUrl: './modal-commande.component.html',
  styleUrls: ['./modal-commande.component.scss']
})
export class ModalCommandeComponent implements OnInit{
  public oneClient: any
  public settings: Settings;
  tableauObjetCommande= [
    { client: "" ,client_id: "",passport_id: "",passport_libelle: "", package_id: "", package_libelle: '' ,produit_libelle:"", observationBanki: '', quartier:"",quantite: "", zone_id: "",  total: ""}
  ]
  values = 0;
  valu :any[];
  value :any[];
  livraisons = 0;
  Satatus_regions = 0;
  verifie_id =0;
  verifie_region : any[] = [] ;
  zone_trouver : any[] = [] ;
  nameQuartier: any;
  id_quartier: any;
  Satatus_commune = 0;
  Status_produit = 0;
  Satatus_zone = 0;

  zoneCalcule = 0;
  reduction = 0;
  reductions: number = 0;
  restant = 0;
  horsZone = 1;
  zoneCalculeLivraison: any;
  plage : any[] = [] ;
  public zzone : any[] = [] ;
  quartiers : any[] = [] ;
  mappedData : any[] = [] ;
  plages : any[] = [] ;
  zoneCalculeLivraisons = 0;
  zoneCalculePlage = 0;
  info: any;
  bam: any;
  produit: any;
  zones: any[] = [];
  prod: any[] = [];
  zones_quartier: any[] = [];
  produi: any[] = [];
  //produitts: any[] = [];
  public produitts=[]
  zonss: any[] = [];
  regions: any[] = [];
  //panier: any[];
  etatPadding: boolean = true;
  etatPaddings: boolean = true;
  verify: boolean = true;
  quantiteIssupToPlage: boolean = true;
Verify1: boolean = true
Verify2: boolean = true
isLoading : boolean = false
//plages
max_plage : any[] = [];
test : any;
total : number = 0;
current_quantite : number = 0;
camions : any[] = [];
 montant : number = 0 ;
voyage : number = 0 ;
livraison: any = '';
payement: number = 0;
subscription: Subscription;
lieux: any;
componentPanier: ProduitCommandeComponent
 public allclient: any      
 public id: any
 public b= new BehaviorSubject([])
 public c= this.b.asObservable()

  constructor(
     private _client: ClientService,
     private zoneService:ZoneService,
     public print: PrintService,
     private activatedRoute: ActivatedRoute,
     private sharedService: SharedService,
     private produitService:ProduitService,
     private communeService:CommuneService,
     public appSettings:AppSettings,
     public userService:UserService,
      private fb: FormBuilder,
      public dialogRef: MatDialogRef<ModalCommandeComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
       private zoneServices: ZoneService,
       private regionService: RegionService,
      public role: RoleService
     ){

      this.id= this.activatedRoute.snapshot.paramMap.get('id')
      if(localStorage.getItem('tableauToAdd') == null){
        localStorage.setItem("tableauToAdd", JSON.stringify(this.tableauObjetCommande))
        this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))
  
     }
      else{
       this.tableauObjetCommande= JSON.parse(localStorage.getItem('tableauToAdd'))  
      }
    this.settings = this.appSettings.settings; 
   
  }

  public getProduitByPassport(){
    if(this.data.libelle_passport == 'Briquetérie'){
      this.produitts = [];
      
    }else if(this.data.libelle_passport == 'Pdv'){
      

      Produit.forEach(item => {
        if(item['libelle'] == 'Ciment'  ) {
      
          this.produitts.push(item);

         }

  
    });
      
      //this.produitts= ['ciment']
    }else if(this.data.libelle_passport == 'Salarié'){
      this.produitts = Produit
    }
    

  }

  selectRegion(event: any){
   
   
    this.Satatus_regions = event.target.value;
    this.verifie_id = event.target.value;
   
    this.communeService.getAllCommuneByRegion(this.verifie_id).subscribe(res => {
      this.verifie_region= res;
      
   // this.getProduit();
    })
      
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }
  
  selectProduit(event: any){ 
    this.Status_produit = event.target.value;
    
    }

  getQuartier(event: any){
    this.nameQuartier = event.target.value
    console.log("test ",this.nameQuartier)
    console.log("test region ",this.Satatus_regions)

    
    this.zoneService.rechercheQuartier(this.nameQuartier).subscribe(res => {
      if(res[0] != null){

           this.id_quartier = res[0].commune_id;
           this.zoneService.rechercheZone(this.id_quartier).subscribe(ress => {
        this.zonss =  [];

if(ress[0].region_id == this.Satatus_regions){
        ress[0].plage.forEach(item => {
          

           if(this.data.passport_id == item['passport_id'] && this.data.id ==  item['package_id']  ) {
              if(this.Status_produit == 0){ 
                this.zones = ress;
                this.zones_quartier = this.zones;
            }else{ 
              if(item['produit_id'] == this.Status_produit){ 

                this.zones = ress;
                this.zones_quartier = this.zones;
                this.isLoading = false;

              }
             }

          }
       });
 
      }


  
   // this.getProduit();
    })
 
  }
   // this.getProduit();
    })




    this.getZoneByQuartier(this.verifie_region,this.nameQuartier)
  }

  formGroup = this.fb.group({
    quantite: ['', [Validators.required, Validators.pattern(/^[0-9+]{1,}$/)]],
    client: ['', [Validators.required]],
    telephone: ['', [Validators.required]],
    zone_id: ['', []],
    idClient: ['', []],
    livraison: [0,[Validators.required]],
    client_id: ['', []],
    produit: ['', []],
    produit_id: ['', []],
    observationBanki: ['', []],
    marque: ['', []],
    date_commande: ['', []],
    categorie_id : [{nom: ""}, []],
    zone: ['', []],
    quartier: ['', []],
    avance:['',[]],
    delais:['',[]],
    montantTotal:[0,[]],
   
    montant_payer:[0,[]],
    reste_payer:[0,[]],
    identifiant:[0,[]],
  });

  ngOnInit(): void {

    console.log("le status du produit",this.Status_produit)
    this.getProduitByPassport()
    this.getRegion();
    this.getAllClient()
    this.getClientById(this.data.client_id)
  }

 

  public getAllClient(){
     this._client.getAllClient()
      .subscribe(data=>{
        this.allclient = data
      })

  }

  
  ajouterUnProduitAuPanier( quantite : any, zone_id : any,quartier:any, produit: any, total :any){
   // En fait clientId est l'identitifiant de la personne connectée
   // Appelons idClient, l'identifiant du client selectionné dans le combobox du client
  //  { client: "" ,client_id: "",passport_id: "",passport_libelle: "", package_id: "", package_libelle: '' ,produit_id:"",produit_libelle:"", quartier:"",quantite: "", zone_id: "",  total: "", montant: ""}
 
   const obj={
     client: this.oneClient[0].name+ ' '+this.oneClient[0].prenoms,
     client_id: this.data.client_id,
     passport_id: this.data.passport_id,
     passport_libelle: this.data.libelle_passport,
     package_id: this.data.id,
     package_libelle: this.data.package_libelle,
     produit_libelle: produit || '',
     observationBanki: '',
     quartier:quartier,
     quantite: quantite,
     zone_id: zone_id,
     total: total,
    }
      if(this.voirSiProduitEstChoisi(produit) == true){
        obj.client = this.oneClient[0].prenoms + " " + this.oneClient[0].name
        this.tableauObjetCommande.push(obj)
        localStorage.setItem('tableauToAdd',JSON.stringify(this.tableauObjetCommande))
        this.tableauObjetCommande = JSON.parse(localStorage.getItem('tableauToAdd'))  
        this.sharedService.taille= this.tableauObjetCommande.length
      }
      else{
      }
  }
  public getClientById(event: any){
    this._client.getClientById(event)
      .subscribe((data: any)=>{
        this.oneClient= data
      })

  }


 voirSiProduitEstChoisi(produit: any): Boolean{
    this.tableauObjetCommande = JSON.parse(localStorage.getItem('tableauToAdd'))
    let tab= this.tableauObjetCommande.filter(data=> data.produit_libelle == produit)
    if(tab.length==0){
        return true;
    }
    else{
      return false;
    }
 }

  onSubmit(){
    this.etatPadding = false;
    /*if(this.livraison == 'Oui'){
      this.montantTotal.setValue(this.montant); 
      this.montant_payer.setValue(this.montant * this.payement - this.reduction );
      this.reste_payer.setValue(this.montant - this.montant * this.payement);
    }else if(this.livraison == 'Non'){
      this.montantTotal.setValue((this.values == 0 ? 1 : this.values) * this.zoneCalcule); 
      this.montant_payer.setValue((this.values == 0 ? 1 : this.values) * this.zoneCalcule );
    }*/
   // this.produit_id.setValue(this.produit?._id);
   // this.marque.setValue(this.produit._id.categorie_id?.nom);
   this.montantTotal.setValue(this.montant); 

    this.client_id.setValue(this.userService.getUserDetails()._id);
   
   this.ajouterUnProduitAuPanier(this.formGroup.value.quantite,this.formGroup.value.zone_id,this.formGroup.value.quartier,this.formGroup.value.produit,this.formGroup.value.montantTotal)
    this.dialogRef.close();
    console.log("form value",this.formGroup.value)
  }

  onChange(event: any){
    this.livraison = event.target.value;
  }

  onPayement(event: any){
    this.payement = event.target.value;
  }

  getRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.regions = res;
    })
  }


  
  public getZoneByQuartier(tab: any, quartier: any){
    for (let i = 0; i < tab.length; i++) {
      this.zoneService.allzones(tab[i]._id).subscribe((data: any) => {
            let c= data.filter((element: any)=> element.localite== quartier)
            if(c.length != 0){
              this.b.next(tab[i])
              this.getData()
              return
            } 
            else{
            }
            })
      // for (let j = 0; j < tab[i].plage.length; j++) {
      //   console.log(tab[i].plage)
     
      // }
    }
  }

  public getData(){
    this.b.subscribe(data=>{
      this.zzone.push(data)
    })
  }

  onReduction(event: any){
    this.reduction = +<number>event.target.value;
  //  var y: number = +this.values;
  this.reductions = this.reduction;
    
  }

  selectCommune(event: any){
    console.log("status produit",this.Status_produit)
    this.Satatus_commune = event.target.value;
    console.log("barry",this.Satatus_commune);
    this.regionService.getAllRegion().subscribe(res => {
      this.prod = res; 
    })
    this.zones_quartier =  [];
   
      this.isLoading = true;
   
      this.zones =  [];
      this.zoneServices.rechercheQuartier(this.Satatus_commune).subscribe(res => {
        res.forEach(items => {
         this.zonss =  [];
          items.plage.forEach(item => {
            if(this.data.passport_id == item['passport_id'] && this.data.id ==  item['package_id']  ) {
                if(item['produit_id'] == this.Status_produit){ 
                  this.zones.push(items);
                  this.zones_quartier = this.zones;
                  console.log("mes donnes ",this.zones);
                  this.isLoading = false;
                }
             }
      
        });
  
      });
      console.log("zone bella",this.zones_quartier)
      this.livraisons = event.target.value;
      this.lieux = this.zones.filter(result => {
        return result._id == this.livraisons;
      }); 
      console.log("zone aliou", this.lieux)
     // this.plage = this.zones[0].plage;

     // this.plage = this.lieux[0].plage;
      //this.plages = this.lieux[0].prix;
        this.zoneCalcule = 1;
      })
    
    console.log("bella barry id",this.Satatus_commune);

    this.zoneServices.QuartierByZone(this.Satatus_commune).subscribe(res => {
      this.quartiers = res;
      console.log("bella aliou ",this.quartiers);
 
    })
  }



  onKey(event: any){
    this.max_plage =[];
    this.camions =[];
    this.values = 1;
    this.values = +<number>event.target.value;
    var y: number = +this.values;
    this.quantiteIssupToPlage = true;
    
    this.current_quantite = this.values;
 

 
    console.log("plage bella",this.zones);

  this.zonss =  [];
  this.zones[0].plage.forEach(item => {
  
    if(this.data.passport_id == item['passport_id'] && this.data.id ==  item['package_id'] ) {
      if(this.Status_produit == 0){ }
      this.montant = item['total'] * this.values;
      console.log("total 1",this.montant);
     }else if (this.data.passport_id == item['passport_id'] && item['produit_id'] ==  this.Status_produit){
      this.montant = item['total'] * this.values;
     }
   
    

});



   
    
    
    const countryMap = {};
    
    this.camions.forEach(item => {
       countryMap[item.name] = [ ...( countryMap[item.name] || [] ), item];
    });
     this.mappedData = Object.entries(countryMap).map(entry => ({
      name: entry[0],
      nombre: entry[1]
   }));
   console.log("map",this.mappedData);

  }

  getclientSuccess() {
    if (this.client.valid) {
      return true;
    }
  }

  get client(){
    return this.formGroup.get('client');
  }
  get date_commande(){
    return this.formGroup.get('date_commande');
  }

  get idClient(){
    return this.formGroup.get('idClient');
  }
  get marque(){
    return this.formGroup.get('marque');
  }
  get client_id(){
    return this.formGroup.get('client_id');
  }

  get produit_id(){
    return this.formGroup.get('produit_id');
  }

  get  montantTotal(){
    return this.formGroup.get('montantTotal');
  }
 
  get  montant_payer(){
    return this.formGroup.get('montant_payer');
  }
  get  avance(){
    return this.formGroup.get('avance');
  }
  get  zone(){
    return this.formGroup.get('zone');
  }
  get  zoness(){
    return this.formGroup.get('zoness');
  }
  get  reste_payer(){
    return this.formGroup.get('reste_payer');
  }
  get  identifiant(){
    return this.formGroup.get('identifiant');
  }
  getclientError() {
    if (this.client.invalid && (this.client.dirty || this.client.touched)) {
      if (this.client.errors.required) {
        return 'Le client est requis!!';
      }
    }
  }
  gettelephoneSuccess() {
    if (this.telephone.valid) {
      return true;
    }
  }

  get telephone(){
    return this.formGroup.get('telephone');
  }
 
  gettelephoneError() {
    if (this.telephone.invalid && (this.telephone.dirty || this.telephone.touched)) {
      if (this.telephone.errors.required) {
        return 'Le numero est requis!!';
      }
    }
  }
  getnomSuccess() {
    if (this.nom.valid) {
      return true;
    }
  }

  get nom(){
    return this.formGroup.get('nom');
  }
 
  getnomError() {
    if (this.nom.invalid && (this.nom.dirty || this.nom.touched)) {
      if (this.nom.errors.required) {
        return 'Le nom est requis!!';
      }
    }
  }
  getQuantiteError() {
    if (this.quantite.invalid && (this.quantite.dirty || this.quantite.touched)) {
      if (this.quantite.errors.required) {
        return 'La Quantitée est requise!!';
      }else if(this.quantite.errors.pattern){
        return 'La Quantitée doit etre un entier!!';
      }
    }
  }
  getQuantiteSuccess() {
    if (this.quantite.valid) {
      return true;
    }
  }
  get delais(){
    return this.formGroup.get('delais');
  }
  get quantite(){
    return this.formGroup.get('quantite');
  }

  get zone_id(){
    return this.formGroup.get('zone_id')
  }
}
