import { UserService } from './user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private userService: UserService) { }

  public getRoleAdmin(){
    if(this.userService.getUserDetails().role == 'Agence'){
      return true;
    }else{
      return false;
    }
  }
  public getRoleValide(){
    if(this.userService.getUserDetails().valider == 'valider'){
      return true;
    }else{
      return false;
    }
  }
  public getSeniore(){
    if(this.userService.getUserDetails().types == '3'){
      return true;
    }else{
      return false;
    }
  }
  public getJuniore(){
    if(this.userService.getUserDetails().types == '2'){
      return true;
    }else{
      return false;
    }
  }
  public getExterne(){
    if(this.userService.getUserDetails().types == '1'){
      return true;
    }else{
      return false;
    }
  }
  public getRoleFournisseur(){
    if(this.userService.getUserDetails().role == 'Fournisseur'){
      return true;
    }else{
      return false;
    }
  }
  public getRoleSAdmin(){
    if(this.userService.getUserDetails().role == 'Sadmin'){
      return true;
    }else{
      return false;
    }
  }

  public getRoleDistributeur(){
    if(this.userService.getUserDetails().role == 'Distributeur'){
      return true;
    }else{
      return false;
    }
  }
  public getRoleFacturation(){
    if(this.userService.getUserDetails().role == 'Facturation'){
      return true;
    }else{
      return false;
    }
  }
  public getRoleClient(){
    if(this.userService.getUserDetails().role == 'user'){
      return true;
    }else{
      return false;
    }
  }

  public getClientFournisseurOrAdmin(){
    if(this.userService.getUserDetails().role == 'user'){
      return 'Client/Fournisseur';
    }else{
      return 'BankiTruck';
    }
  }
}
