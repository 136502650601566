export const Passport =[
    {
        "id": 1,
        "libelle":  "Briquetérie",
        "image": "../../../assets/img/users/burqueterie.jpeg"
    },
    {
        "id": 2,
        "libelle":  "Pdv",
        "image": "../../../assets/img/users/pdv.jpeg"
    },
    {
        "id": 3,
        "libelle":  "Salarié",
        "image": "../../../assets/img/users/salarier.jpeg"
    }


]