import { ClientService } from './../../services/client.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modif-client',
  templateUrl: './modif-client.component.html',
  styleUrls: ['./modif-client.component.scss']
})
export class ModifClientComponent {
  client: any;
  etatPadding: boolean = true;
  public typePiece= ['passport', 'permis de conduire', "carte d'identité"]
  
  constructor(private fb: FormBuilder, private clientService: ClientService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<ModifClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getClientById();
  }

  getClientById(){
    this.clientService.getClientById(this.data.id).subscribe(res => {
      this.client = res;
      this.initialiseForms();
    })
  }

 

  clientForm =  this.fb.group({
    name: ['',  {
      validators: [
      Validators.required,
    ]},],
    prenoms: ['',  {
      validators: [
      Validators.required,
    ]},],
    telephone: ['',  {
      validators: [
      Validators.required,
    ]},],
    type_identite: ['',  {
      validators: [
      Validators.required,
    ]},],
    numero_piece: ['',  {
      validators: [
      Validators.required,
    ]},],
   
   
  });


 
  onSubmit(){
    this.etatPadding = false;
        this.clientService.updateClient(this.data.id, this.clientForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;
        })
   
  }

  private initialiseForms(){
    this.clientForm.patchValue({
      name: this.client[0].name ? this.client[0].name : '',
      prenoms: this.client[0].prenoms ? this.client[0].prenoms : '',
      telephone: this.client[0].telephone ? this.client[0].telephone : '',
      numero_piece: this.client[0].numero_piece ? this.client[0].numero_piece : '',
      type_identite: this.client[0].type_identite ? this.client[0].type_identite : '',
    })
  }

  
   get libelle(){
     return this.clientForm.get('libelle');
   }
 
}
