import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { CommentaireService } from '../services/commentaire.service';

@Component({
  selector: 'app-observation-banki',
  templateUrl: './observation-banki.component.html',
  styleUrls: ['./observation-banki.component.scss']
})
export class ObservationBankiComponent implements OnInit {
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  public commentaire: any
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(private commentaireService: CommentaireService,@Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private _snckBar: SnackBarService,public dialogRef: MatDialogRef<ObservationBankiComponent>,public userService:UserService,public appSettings:AppSettings,private fb: FormBuilder,private produitService: ProduitService) {
    this.settings = this.appSettings.settings;     
  }
  ngOnInit(): void {
   this.getCommentaire()
  }
  observationForm =  this.fb.group({
    user: [this.data.user,  {
      validators: [
      Validators.required,
    ]},],
    image: [''],
    user_id: [''],
    idCommande: [this.data.id, []],
   comment: ['',[]],
   
   
  });
  onSubmit(){
    this.etatPadding = false;
    const formData = new FormData();
    formData.append('file', this.image.value);
    this.commentaireService.upload(formData)
    .subscribe(res=>{
      this.user_id.setValue(this.userService.getUserDetails()._id)
      console.log("le payload", this.observationForm.value)
      this.commentaireService.addCommentaire(this.observationForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Observation ajoutée!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = true;
      });
    })
   
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (fileInput.target.files.length > 0) {
      const file = fileInput.target.files[0];
      this.observationForm.get('image').setValue(file);
      this.bam = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  
  getCommentaire(){
    const obj={
      idCommande: this.data.id
    }
    this.commentaireService.getCommentaireByProduitId(obj)
    .subscribe(data=>{
      this.commentaire= data
    })
  }

  get comment(){
    return this.observationForm.get('comment');
  }
  get user_id(){
    return this.observationForm.get('user_id');
  }
  get image(){
    return this.observationForm.get('image');
  }
  //Input with a custom ErrorStateMatcher
  //Input with a clear button

  




}
