import { Router } from '@angular/router';
import { style } from '@angular/animations';
import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { Client } from 'src/app/models/client';
import { AjoutClientComponent } from '../ajout-client/ajout-client.component';
import { ModifClientComponent } from '../modif-client/modif-client.component';
import { DeleteClientComponent } from '../delete-client/delete-client.component';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-liste-client',
  templateUrl: './liste-client.component.html',
  styleUrls: ['./liste-client.component.scss']
})
export class ListeClientComponent {
  public client: Client[]
  public isReady= false
  pageSize = 6;
  page = 1;
  count = 0;
  title = '';

  pageSizes = [6, 12, 18];
  dataSource = new MatTableDataSource<PeriodicElement>(null);
  subscription: Subscription;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  public settings: Settings;
  constructor(public print: PrintService,private _router: Router,private userService:UserService, private dialog: MatDialog,public appSettings:AppSettings,private clientService:ClientService) {
   this.settings = this.appSettings.settings; 
  }
  
  ngOnInit(): void {
  
    
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getPaginatedClient()
    });
  
  }
  getPaginatedClient(){
   this.clientService.getPaginatedClient(this.userService.getUserDetails()._id).subscribe((res: any) => {
     this.client = res.docs;
     this.isReady= true

   });


  }
  redirectToCommandPage(id: any){
   this._router.navigateByUrl('/passport/'+ id) 
  }
  
  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.clientService.getPaginatedClient(this.userService.getUserDetails()._id).subscribe((res: any) => {
      this.client = res.docs;
      this.count = res.totalDocs;
      this.page = params.page;
    });
  }

  getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
    let params: any = {};
  
    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }
  
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.retrieveTutorials();
  }

  public onPageChanged(event){
    this.page = event;
    this.retrieveTutorials();
    if(this.settings.fixedHeader){      
        document.getElementById('main-content').scrollTop = 0;
    }
    else{
        document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    }
  } 

  getAllOrders() {
    this.clientService.getAllClient().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return data
    })}
  
  addClient(){
    this.dialog.open(AjoutClientComponent, {
      width: '500px',
      height: '400px',
    })
  }

  deleteClient(id){
    this.dialog.open(DeleteClientComponent, {
      data: {id: id}
     
    })
  }
  upadteClient(id){
    this.dialog.open(ModifClientComponent, {
      data: {id: id}
     
    })
  }
  
  
}

export interface PeriodicElement {
  libelle: string
   
  }